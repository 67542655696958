//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ItemStep',
  components: {
    IconArrowRight: () => import('@tada/icons/dist/IconChevronRightS.vue'),
    // CheckCircle: () => import('@tada/icons/dist/IconCheckCircle2M.vue'),
  },
  props: {
    number: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    past: {
      type: Boolean,
      default: false,
    },
  },
}
